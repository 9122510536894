import React, { useRef } from 'react';
import '../../assets/styles/PopupCompartir.css';
import cerrarIcono from '../../assets/images/cerrar.svg';

const PopupCompartir = ({ imageUrl, handleClose }) => {
  const urlRef = useRef(null);

  const copyUrlToClipboard = () => {
    if (urlRef.current) {
      urlRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div className="contenedor-popup-compartir">
      <div className='fondo-oscuro' onClick={handleClose}></div>
      <div className="contenido-popup-compartir">
        <button className="boton-cerrar-compartir" onClick={handleClose}><img className='imgCerrarPopup' src={cerrarIcono} /></button>
        <h2>Compartir enlace del recurso</h2>
        <hr/>
        <div className='container-input-url'>
            <input type="text" className="url-imagen" ref={urlRef} readOnly value={imageUrl} />
            <button className="boton-copiar" onClick={copyUrlToClipboard}>Copiar URL</button>
        </div>
      </div>
    </div>
  );
};

export { PopupCompartir };