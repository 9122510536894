import React, { useEffect, useState } from 'react';
import '../../assets/styles/Corporativo.css';
import { Input, Icon } from 'semantic-ui-react';
import { MdDashboard } from 'react-icons/md';
import { get } from '../../Api';
import { Link } from 'react-router-dom';

function Corporativos() {
  const [isHovered, setIsHovered] = useState(false);
  const [corporativos, setCorporativos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [visibleItems, setVisibleItems] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        setIsLoading(true);
        const response = await get(`/getCorporativos`);
        setCorporativos(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);


  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickVermas = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };

  const filterPromociones = (corporativo) => {
    const searchKeywords = searchValue.toLowerCase().split(' ');
    const promocionNombre = corporativo.Nombre.toLowerCase();

    const nameMatch = searchKeywords.some(
      (keyword) => promocionNombre.includes(keyword)
    );
    return nameMatch;
  };

  const sortedCorporativos = searchValue ? corporativos.filter(filterPromociones) : corporativos;

  return (
    <div className='principal' style={{ marginLeft: '7%' }}>

      <div className='miga-de-pan-categoria'>
        <Link style={{ color: 'black' }} to='/'>Inicio</Link>
        {' > '}
        <label>Corporativos</label>
      </div>

      <div style={{ fontSize: '32px', marginBottom: '40px' }} className='text-promociones' >Corporativos</div>

      <div className='input-buscar-promociones' style={{ width: '50%', border: 'none', marginBottom: '40px' }}>
        <Input
          style={{ width: '100%' }}
          icon
          placeholder='Buscar en esta categoría...'
          value={searchValue}
          onChange={handleSearchChange}
        >
          <input />
          <Icon
            style={{ color: 'black', backgroundColor: '#f9c632' }}
            name='search'
            inverted
            square='true'
            link
          />
        </Input>
      </div>


      {isLoading ? (
        <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px', marginTop: '40px' }}>
          Cargando corporativos...
        </div>
      ) : (
        <>
          <div className='grid-promociones'>
            {sortedCorporativos.length > 0 ? (
              sortedCorporativos.slice(0, visibleItems).map((corporativo, index) => (
                <Link
                  key={index}
                  to={`/detalle_corporativo/${corporativo.Id}/${corporativo.Nombre.toLowerCase()}`}
                  state={{ idCorporativo: corporativo.Id, nombreCorporativo: corporativo.Nombre }}
                  title={corporativo.Nombre} // Agrega el atributo "title" con el nombre de la promoción
                >
                  <div class="grid-item-promociones">
                    <div class="image-container">
                      {corporativo.FotoPrincipal && Object.keys(corporativo.FotoPrincipal).length > 0 && (
                        <img src={corporativo.FotoPrincipal[Object.keys(corporativo.FotoPrincipal)[0]][1]} alt={`Imagen ${index + 1}`} />
                      )}
                    </div>
                    <div class="name">
                      {corporativo.Nombre}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px', marginTop: '40px' }}>
                No hay corporativos disponibles.
              </div>
            )}
          </div>
          {!isLoading && sortedCorporativos.length > 0 && visibleItems < sortedCorporativos.length && (
            <div>
              <button
                className={`button-ver-mas-promociones ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClickVermas}
              >
                <span className="icon"><MdDashboard /></span>
                <span className="text">Ver más</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Corporativos;
