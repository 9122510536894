import React, { useEffect, useState } from 'react';
import { get } from '../../Api';
import "../../assets/styles/DetallePromocion.css";
import { useLocation, Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import BotonDescargar from '../../components/botones_descargar/BotonDescargar';
import IconDescargar from '../../components/botones_descargar/IconDescargar';
import { BotonVistaPreviaImagen } from '../../components/botones_vista_previa/BotonVistaPreviaImagen';
import { PopupVistaPreviaImagen } from '../../components/popups/PopupVistaPreviaImagen';
import { PopupCompartir } from '../../components/popups/PopupCompartir';
import { BotonCompartir } from '../../components/botones_compartir/BotonCompartir';
import { useParams } from 'react-router-dom';

function DetallePromocion() {

    const location = useLocation();
    const {idPromocionParams, nombrePromocionParams} = useParams();
    console.log("promos param"+idPromocionParams)
    const idPromocion = (location.state?.idPromocion) ? location.state?.idPromocion : idPromocionParams //recibimos el idPromocion
    const nombrePromocion = (location.state?.nombrePromocion) ? location.state?.nombrePromocion : nombrePromocionParams//recibimos el idPromocion
    const [promocion, setMoto] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filtroActivo, setFiltroActivo] = useState('Todo');

    const [mostrarImagenes, setMostrarImagenes] = useState(4);
    const [mostrarVideos, setMostrarVideos] = useState(4);
    const [mostrarEditables, setMostrarEditables] = useState(5);
    const [mostrarDocumentos, setMostrarDocumentos] = useState(5);
    const [showPopup, setShowPopup] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Estado para almacenar la URL de la imagen seleccionada

    const filtrosBotones = ["Todo",
        "Imágenes",
        "Videos",
        "Editables",
        "Documentos"]

    const handleFilterChange = (filter) => {
        setFiltroActivo(filter);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);
                const response = await get(`/getPromocion/${idPromocion}`);
                setMoto(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleSearch = () => {
        // Aquí puedes agregar la lógica para realizar la búsqueda
        console.log('Realizando búsqueda...');
    };
    const handleShowPopup = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setShowPopup(true);
      };
      const handleShowSharePopup = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setShowSharePopup(true);
      };
    
      const handleClosePopup = () => {
        setShowPopup(false);
      };
      const handleClosesharePopup = () => {
        setShowSharePopup(false);
      };
  

    const totalImagenes = promocion && promocion[0]?.Imagenes ? Object.entries(promocion[0].Imagenes).length : 0;
    const totalVideos = promocion && promocion[0]?.Videos ? Object.entries(promocion[0].Videos).length : 0;
    const totalEditables = promocion && promocion[0]?.Editables ? Object.entries(promocion[0].Editables).length : 0;
    const totalDocumentos = promocion && promocion[0]?.Documentos ? Object.entries(promocion[0].Documentos).length : 0;

    return (
        <div >

            <div className='miga-de-pan-categoria'>
                <Link style={{ color: 'black' }} to='/'>Inicio</Link>
                {' > '}
                <Link style={{ color: 'black' }} to='/promociones'>Promociones</Link>
                {' > '}
                <label>Promoción</label>
            </div>

            <div className='div-detail' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div className='titulo-detalle-promo'>{promocion[0]?.Nombre}</div>

                {/* <div className='input-buscar-detail' style={{ width: '40%', border: 'none' }}>
          <Input
            style={{ width: '100%' }}
            icon={true}
            placeholder={`Buscar en ${promocion[0]?.Nombre}...`}
          >
            <input />
            <Icon
              style={{ color: 'black', backgroundColor: '#f9c632' }}
              name="search"
              inverted
              square="true"
              link
              onClick={handleSearch}
            />
          </Input>
        </div> */}
            </div>

            {isLoading ? ( // Mostrar el loader mientras se cargan las marcas
                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>Cargando archivos...</div>
            ) : (
                <>
                    <div>
                        <div className='text subtitulo' style={{ fontSize: '15px', marginBottom: 0 }}>Fecha inicio <span style={{ paddingLeft: '10px' }}>{promocion[0]?.FechaInicio}</span></div>
                        <div className='text subtitulo' style={{ fontSize: '15px', marginBottom: '40px' }}>Fecha fin <span style={{ paddingLeft: '27px' }}>{promocion[0]?.FechaFin}</span></div>
                    </div>

                    <div className='text subtitulo' >Filtra por tipo de archivo</div>
                    <div className="filter-buttons-detalle-promo" style={{ marginBottom: '50px' }}>
                        {filtrosBotones.map((filter) => (
                            <button
                                key={filter}
                                className={filter === filtroActivo ? 'active' : ''}
                                onClick={() => handleFilterChange(filter)}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>

                    {(filtroActivo === 'Imágenes' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Imágenes</div>
                            <div className="grid-detalle-promo" >
                                {promocion && promocion[0]?.Imagenes && Object.entries(promocion[0].Imagenes).length > 0 ? (
                                    Object.entries(promocion[0].Imagenes).slice(0, mostrarImagenes).map(([key, value], index) => (
                                        <div key={key} className="grid-item-search">
                                            <div className='fecha-carga-material'>
                                                <div className='nombre-archivo-material'>{value[3]}</div>
                                                <div className='div-video-text-material'><label>Imagen</label>
                                                </div>
                                            </div>
                                            <img src={value[1]} alt={`Imagen ${index + 1}`} />
                                            <div className='datos-detalle-promo'>
                                                <div className='tecni-info'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Peso</label>
                                                        <span style={{ color: '#000' }}>{value[5]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Resolución</label>
                                                        <span style={{ color: '#000' }}>{value[4]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Formato</label>
                                                        <span style={{ color: '#000' }}>{value[6]}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BotonDescargar direccion={value[1]} nombre={value[3]} />
                                                </div>
                                                <div>
                                                    <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                                                </div>
                                                <div>
                                                    <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarImagenes < totalImagenes && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarImagenes(mostrarImagenes + mostrarImagenes)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Videos' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Videos</div>
                            <div className="grid-detalle-promo" >
                                {promocion && promocion[0]?.Videos && Object.entries(promocion[0].Videos).length > 0 ? (
                                    Object.entries(promocion[0].Videos).slice(0, mostrarVideos).map(([key, value], index) => (
                                        <div key={key} className="grid-item-detalle-promo">
                                            <div className='fecha-carga-material'>
                                                <div className='nombre-archivo-material'>{value[3]}</div>
                                                <div className='div-video-text-material'><label>Video</label>
                                                </div>
                                            </div>
                                            <video src={value[1]} />
                                            <div className='datos'>
                                                <div className='tecni-info'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Peso</label>
                                                        <span style={{ color: '#000' }}>{value[5]} </span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Resolución</label>
                                                        <span style={{ color: '#000' }}>{value[4]} </span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Formato</label>
                                                        <span style={{ color: '#000' }}>{value[6]} </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BotonDescargar direccion={value[1]} nombre={value[3]} />
                                                </div>
                                                <div>
                                                    <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                                                </div>
                                                <div>
                                                    <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarVideos < totalVideos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarVideos(mostrarVideos + mostrarVideos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Editables' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Editables</div>
                            {promocion && promocion[0]?.Editables && Object.entries(promocion[0].Editables).length > 0 ? (
                                <>
                                    {Object.entries(promocion[0].Editables).slice(0, mostrarEditables).map(([key, value]) => (
                                        <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                            <div className="container-icono-detalles">
                                                <Icon
                                                    className='download-icon'
                                                    style={{
                                                        color: '#706f6f',
                                                        marginTop: '16px',
                                                        float: 'left',
                                                        marginLeft: '10%',
                                                        fontSize: '26px'
                                                    }}
                                                    name="file"
                                                />
                                            </div>
                                            <div className="container-text-detalles">
                                                <div className='conten-text-manual-carga'>
                                                    {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                    ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                    <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                                </div>
                                            </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                            <div className="container-iconos-detalles">
                                                <IconDescargar direccion={value[1]} nombre={value[3]} />
                                            </div>
                                        </div>
                                    ))}
                                    {mostrarEditables < totalEditables && (
                                        <div style={{ marginBottom: '30px' }}>
                                            <button className='button-ver-mas-archivos'
                                                onClick={() => setMostrarEditables(mostrarEditables + mostrarEditables)}
                                            >
                                                <span className="text">Ver más</span>
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Documentos' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Documentos</div>
                            {promocion && promocion[0]?.Documentos && Object.entries(promocion[0].Documentos).length > 0 ? (
                                <>
                                    {Object.entries(promocion[0].Documentos).slice(0, mostrarDocumentos).map(([key, value]) => (
                                        <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                            <div className="container-icono-detalles">
                                                <Icon
                                                    className='download-icon'
                                                    style={{
                                                        color: '#706f6f',
                                                        marginTop: '16px',
                                                        float: 'left',
                                                        marginLeft: '10%',
                                                        fontSize: '26px'
                                                    }}
                                                    name="file"
                                                />
                                            </div>
                                            <div className="container-text-detalles">
                                                <div className='conten-text-manual-carga'>
                                                    {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                    ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                    <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                                </div>
                                            </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                            <div className="container-iconos-detalles">
                                                <IconDescargar direccion={value[1]} nombre={value[3]} />
                                            </div>
                                        </div>
                                    ))}
                                    {mostrarDocumentos < totalDocumentos && (
                                        <div style={{ marginBottom: '30px' }}>
                                            <button className='button-ver-mas-archivos'
                                                onClick={() => setMostrarDocumentos(mostrarDocumentos + mostrarDocumentos)}
                                            >
                                                <span className="text">Ver más</span>
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                        </div>
                    )}
                        {showPopup && <PopupVistaPreviaImagen handleClose={handleClosePopup} imageUrl={selectedImageUrl} />}
                        {showSharePopup && <PopupCompartir handleClose={handleClosesharePopup} imageUrl={selectedImageUrl} />}
                </>
            )}
        </div >
    );
};

export default DetallePromocion;