import React, { useState } from 'react';
import { FaBars, FaRegTimesCircle } from "react-icons/fa";
import { NavLink, useNavigate } from 'react-router-dom';
import { Input, Icon } from 'semantic-ui-react';
import Logo from '../../assets/images/logo.png';
import IconLogo from '../../assets/images/icon_logo.png';
import { MdCorporateFare, MdOutlineStarBorderPurple500, MdOutlineSearch } from "react-icons/md";
import { FaMotorcycle } from "react-icons/fa";
import { SlBadge } from "react-icons/sl";


const SideNavLeft = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(''); // Estado para almacenar el texto de búsqueda


  const handleSearch = () => {

    // Navegar a otro componente y pasar el término de búsqueda como parámetro
    navigate('/resultado_busqueda_a', { state: { busqueda: searchTerm } });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


  const menuItem = [
    {
      path: "/",
      name: "Motos",
      icon: <FaMotorcycle />
    },
    {
      path: "/material",
      name: "Material",
      icon: <SlBadge />
    },
    {
      path: "/promociones",
      name: "Promociones",
      icon: <MdOutlineStarBorderPurple500 />
    },
    {
      path: "/corporativos",
      name: "Corporativos",
      icon: <MdCorporateFare />
    }
  ]
  return (
    <div className="container">
      <div style={{ width: isOpen ? "250px" : "48px" }} className="sidebar">
        <div className="top_section">
          <div style={{ display: isOpen ? "block" : "none" }} className="logo"><img src={Logo} alt="Logo" /></div>
          <div style={{ marginLeft: isOpen ? "10px" : "0px", marginBottom: isOpen ? "90px" : "0px" }} className="bars">
            {
              isOpen ?
                <FaRegTimesCircle onClick={toggle} /> :
                <FaBars onClick={toggle} />
            }
          </div>
        </div >
        <div>
          {
            isOpen ?
              <div className='input-buscar' style={{ width: '100%', border: 'none' }}>
                <Input style={{ width: '90%', marginLeft: '5%' }} icon={true} placeholder={`Buscar archivos...`}>
                  <input
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <Icon
                    style={{ color: 'black', backgroundColor: '#f9c632' }}
                    name="search"
                    inverted
                    square="true"
                    link
                    onClick={handleSearch}
                  />
                </Input>
              </div>
              :
              <div >
                <div style={{ marginLeft: '5px', marginTop: '13.96%' }}>
                  <NavLink to={"/"} className="icon_moto">
                    <div ><img src={IconLogo} className="colorize" alt="Imagen" />
                    </div>
                  </NavLink>
                </div>
                <div >
                  <NavLink to={"/resultado_busqueda_c"} className="link" activeclassname="active" >
                    <div className="icon"><MdOutlineSearch />
                    </div>
                  </NavLink>
                </div>
              </div>
          }
        </div>
        {

          menuItem.map((item, index) => (
            <NavLink to={item.path} key={index} className="link" activeclassname="active">
              <div className="icon">{item.icon}</div>
              <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
            </NavLink>
          ))
        }
        <div style={{ marginBottom: '100vh' }}></div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default SideNavLeft;
