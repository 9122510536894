import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import "../../assets/styles/IconDescargar.css";

const IconDescargar = ({ direccion, nombre }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadComplete, setIsDownloadComplete] = useState(false);

  const handleDownload = () => {
    setIsDownloading(true);

    // Simulación de la descarga completada después de 2 segundos
    setTimeout(() => {
      setIsDownloading(false);
      setIsDownloadComplete(true);
      descargarArchivo(direccion, nombre);

      // Restablecimiento del estado después de 1 segundo
      setTimeout(() => {
        setIsDownloadComplete(false);
      }, 1000);
    }, 2000);
  };

  const descargarArchivo = (direccion, nombre) => {
    const direccionSegura = direccion.replace('http:', 'https:'); // Reemplaza 'http:' con 'https:'
  
    fetch(direccionSegura, {
      mode: 'cors' // Agrega la opción 'mode: cors' para permitir solicitudes CORS
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error ' + response.status + ': ' + response.statusText);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = nombre; // Nombre de archivo deseado
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error(error);
      });
  };
  


  return (
    <a download style={{ right: '0' }}>
      <Icon
        className={`download-icon-search ${isDownloading ? 'downloading' : ''} ${isDownloadComplete ? 'complete' : ''}`}
        style={{
          marginTop: '16px',
          float: 'right',
          height: '32px',
          width: '32px',
          marginRight: '3%',
          borderRadius: '50%',
        }}
        name={isDownloadComplete ? 'check' : 'download'}
        square="true"
        link
        onClick={handleDownload}
      />
    </a>
  );
};

export default IconDescargar;
