import React, { useEffect, useState } from 'react';
import '../../assets/styles/Motos.css';
import { Input, Icon } from 'semantic-ui-react';
import { MdDashboard } from 'react-icons/md';
import { useLocation, Link } from 'react-router-dom';
import { get } from '../../Api';
import { useParams } from 'react-router-dom';

function Motos() {
  const location = useLocation();
  const { idMarcaParams, nombreMarcaParams  } = useParams();
  const idMarca = (location.state?.idMarca)? location.state?.idMarca : idMarcaParams; // Recibimos el idMarca
  const nombreMarca = (location.state?.nombreMarca)? location.state?.nombreMarca : nombreMarcaParams // Recibimos el nombreMarca
  const [isHovered, setIsHovered] = useState(false);
  const [motos, setMotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState('default'); // Agregamos el estado para el valor seleccionado del ordenamiento
  const [visibleItems, setVisibleItems] = useState(12); // Estado para controlar la cantidad de elementos visibles

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        const response = await get(`/getMotos/brand${idMarca}`);
        setMotos(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [idMarca]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortValue(event.target.value);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickVermas = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 12); // Incrementar la cantidad de elementos visibles en 12
  };

  const filterMotos = (moto) => {
    const searchKeywords = searchValue.toLowerCase().split(' ');
    const motoNombre = moto.Nombre.toLowerCase();
    const motoModelo = '2023';

    // Verificar si algún keyword coincide con el nombre o modelo de la moto
    return searchKeywords.some(
      (keyword) => motoNombre.includes(keyword) || motoModelo.includes(keyword)
    );
  };

  const sortMotos = (motos) => {
    switch (sortValue) {
      case '1':
        return [...motos].sort((a, b) => a.Nombre.localeCompare(b.Nombre)); // Orden ascendente por nombre
      case '2':
        return [...motos].sort((a, b) => b.Nombre.localeCompare(a.Nombre)); // Orden descendente por nombre
      case '3':
        return [...motos].sort((a, b) => b.Modelo - a.Modelo); // Orden descendente por modelo
      case '4':
        return [...motos].sort((a, b) => a.Modelo - b.Modelo); // Orden ascendente por modelo
      default:
        return motos; // Sin ordenamiento
    }
  };

  const filteredMotos = searchValue ? motos.filter(filterMotos) : motos;
  const sortedMotos = sortMotos(filteredMotos);

  return (
    <div>
      <div className='miga-de-pan-categoria'>
        <Link style={{ color: 'black' }} to='/'>Inicio</Link>
        {' > '}
        <Link style={{ color: 'black' }} to='/'>Marcas</Link>
        {' > '}
        <label>{nombreMarca}</label>
      </div>

      <div className='titulo-categoria'>{nombreMarca}</div>
      <div className='parrafo-categoria'></div>

      <div
        className='div-categorie'
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '40px',
        }}
      >
        <div className='input-buscar-categoria' style={{ width: '40%', border: 'none' }}>
          <Input
            style={{ width: '100%' }}
            icon
            placeholder='Buscar en esta categoría...'
            value={searchValue}
            onChange={handleSearchChange}
          >
            <input />
            <Icon
              style={{ color: 'black', backgroundColor: '#f9c632' }}
              name='search'
              inverted
              square='true'
              link
            />
          </Input>
        </div>

        <div
          className='div-selector'
          style={{ width: '40%', background: '#EFEFEF', borderRadius: '5px', marginRight: '5%' }}
        >
          <select value={sortValue} className='selector' onChange={handleSortChange}>
            <option value='default'>Ordenar</option>
            <option value='1'>{'Nombre (Asc)'}</option>
            <option value='2'>{'Nombre (Des)'}</option>
            <option value='3'>{'Modelo (Mayor a Menor)'}</option>
            <option value='4'>{'Modelo (Menor a Mayor)'}</option>
          </select>
        </div>
      </div>

      {isLoading ? (
        <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>
          Cargando motos...
        </div>
      ) : (
        <div className='grid-categoria'>
          {sortedMotos.length > 0 ? (
            sortedMotos.slice(0, visibleItems).map((moto, index) => (
              <div key={index} className='grid-item-categoria'>
                <div className='div-marca'>
                  <label className='titulo-marca'>{nombreMarca}</label>
                </div>
                <div>
                  {moto.Fotos && Object.keys(moto.Fotos).length > 0 && (
                    <img src={moto.Fotos[Object.keys(moto.Fotos)[0]][1]} alt={`Imagen ${index + 1}`} />
                  )}
                </div>
                <div className='div-ver-mas'>
                  <div className='categoria-info'>
                    <label>{moto.Nombre}</label>
                    <br />
                    <label style={{ fontSize: '10px' }}>Modelo:  {moto.Modelo}</label>
                  </div>
                  <div>
                    <Link key={index} to={`/detalle_moto/${moto.Id}/${moto.Nombre.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '-').replace(/[^\w-]+/g, '')}`}
                      state={{ idMoto: moto.Id, nombreMoto:moto.Nombre,nombreMarca: moto.Marca, idMarca: idMarca }}>
                      <button color='yellow' className='button-ver-mas1'>
                        Ver más
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>
              No hay motos disponibles.
            </div>
          )}
        </div>
      )}

      {!isLoading && sortedMotos.length > 0 && visibleItems < sortedMotos.length && (
        <div>
          <button
            className={`button-ver-mas2 ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClickVermas}
          >
            <span className='icon'>
              <MdDashboard />
            </span>
            <span className='text'>Ver más</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Motos;
