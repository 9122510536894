import React, { useEffect, useState } from 'react';
import '../../assets/styles/Promociones.css';
import { Input, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { MdDashboard } from 'react-icons/md';
import { get } from '../../Api';
import { Link } from 'react-router-dom';

function Promociones() {
  const [isHovered, setIsHovered] = useState(false);
  const [promociones, setPromociones] = useState([]);
  console.log("promociones",promociones)
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [tagPromociones, setTagPromociones] = useState('default');
  const [visibleItems, setVisibleItems] = useState(6);
  const [tiposPromociones, setTiposPromociones] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        setIsLoading(true);
        const response = await get(`/getPromociones`);
        const promocionesActivas = response.data.filter(promocion => {
          const fechaInicio = new Date(promocion.FechaInicio);
          const fechaFin = new Date(promocion.FechaFin);
          const fechaActual = new Date();

          return fechaInicio <= fechaActual && fechaActual <= fechaFin;
        });
        setPromociones(promocionesActivas);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchPromocionesByDate = async () => {
      try {
        setIsLoading(true);
        const response = await get(`/getPromociones`);
        const promociones = response.data.filter((promocion) => {
          const fechaInicio = new Date(promocion.FechaInicio);
          const fechaFin = new Date(promocion.FechaFin);
          return fechaInicio >= new Date(startDate) && fechaFin <= new Date(endDate);
        });
        setPromociones(promociones);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (startDate && endDate) {
      fetchPromocionesByDate();
    }
  }, [startDate, endDate]);


  useEffect(() => {
    const fetchTiposPromociones = async () => {
      try {
        setIsLoading(true);
        const response = await get('/getTiposPromociones');
        const tiposPromocionesOrdenados = response.data.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        setTiposPromociones(tiposPromocionesOrdenados);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchTiposPromociones();
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setTagPromociones(selectedValue);

    if (selectedValue !== 'default') {
      try {
        setIsLoading(true);
        get(`/getPromociones/typepromotion${selectedValue}`).then((response) => {

          const promociones = response.data.filter((promocion) => {
            const fechaInicio = new Date(promocion.FechaInicio);
            const fechaFin = new Date(promocion.FechaFin);
            return fechaInicio >= new Date(startDate) && fechaFin <= new Date(endDate);
          });
          setPromociones(promociones);
          setIsLoading(false);
        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      get(`/getPromociones`).then((response) => {
        const promocionesActivas = response.data.filter(promocion => {
          const fechaInicio = new Date(promocion.FechaInicio);
          const fechaFin = new Date(promocion.FechaFin);
          const fechaActual = new Date();

          return fechaInicio <= fechaActual && fechaActual <= fechaFin;
        });
        setPromociones(promocionesActivas);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickVermas = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 6);
  };

  const filterPromociones = (promocion) => {
    const searchKeywords = searchValue.toLowerCase().split(' ');
    const promocionNombre = promocion.Nombre.toLowerCase();

    const nameMatch = searchKeywords.some(
      (keyword) => promocionNombre.includes(keyword)
    );
    return nameMatch;
  };

  const sortedPromociones = searchValue ? promociones.filter(filterPromociones) : promociones;

  return (
    <div className='principal' style={{ marginLeft: '7%' }}>

      <div className='miga-de-pan-categoria'>
        <Link style={{ color: 'black' }} to='/'>Inicio</Link>
        {' > '}
        <label>Promociones</label>
      </div>
      <div style={{ fontSize: '32px', marginBottom: '40px' }} className='text-promociones' >Promociones</div>
      <div className='div-promotions'>
        <div className='input-buscar-promociones' style={{ width: '25%', border: 'none' }}>
          <Input
            style={{ width: '100%' }}
            icon
            placeholder='Buscar en esta categoría...'
            value={searchValue}
            onChange={handleSearchChange}
          >
            <input />
            <Icon
              style={{ color: 'black', backgroundColor: '#f9c632' }}
              name='search'
              inverted
              square='true'
              link
            />
          </Input>
        </div>

        <div className='div-tag-promociones'>
          <select value={tagPromociones} className='selector' onChange={handleSortChange}>
            <option value='default'>Tags promociones</option>
            {tiposPromociones && tiposPromociones.map((tipoPromocion) => (
              <option key={tipoPromocion.Id} value={tipoPromocion.Id}>{tipoPromocion.Nombre}</option>
            ))}
          </select>
        </div>
        <div className='div-selector-fecha'>
          <DateInput
            style={{ fontSize: '14px', color: 'black', width: '100%', height: '35px' }}
            dateFormat="YYYY-MM-DD"
            placeholder="Fecha inicial"
            value={startDate}
            iconPosition="left"
            closable
            onChange={(event, { value }) => setStartDate(value)}
          />
        </div>

        <div className='div-selector-fecha'>
          <DateInput
            style={{ fontSize: '14px', color: 'black', width: '100%', height: '35px' }}
            dateFormat="YYYY-MM-DD"
            placeholder="Fecha final"
            value={endDate}
            iconPosition="left"
            closable
            onChange={(event, { value }) => {
              // Validar que la fecha final no sea inferior a la fecha inicial
              if (!startDate || value >= startDate) {
                setEndDate(value);
              }
            }}
          />

        </div>
      </div >

      <div className='text-subtitulo-promociones' >Promociones vigentes</div>

      {isLoading ? (
        <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>
          Cargando promociones...
        </div>
      ) : (
        <>
          <div className='grid-promociones'>
            {sortedPromociones.length > 0 ? (
              sortedPromociones.slice(0, visibleItems).map((promocion, index) => (
                <Link
                  key={index}
                  to={`/detalle_promocion/${promocion.Id}/${promocion.Nombre.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '-')}`} 
                  state={{ idPromocion: promocion.Id }}
                  title={promocion.Nombre} // Agrega el atributo "title" con el nombre de la promoción
                >
                  <div class="grid-item-promociones">
                    <div class="image-container">
                      {promocion.Imagenes && Object.keys(promocion.Imagenes).length > 0 && (
                        <img src={promocion.Imagenes[Object.keys(promocion.Imagenes)[0]][1]} alt={`Imagen ${index + 1}`} />
                      )}
                    </div>
                    <div class="name">
                      {promocion.Nombre}
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className='text-promociones' style={{ marginLeft: 0, marginBottom: '40px' }}>
                No hay promociones disponibles.
              </div>
            )}
          </div>
          {!isLoading && sortedPromociones.length > 0 && visibleItems < sortedPromociones.length && (
            <div>
              <button
                className={`button-ver-mas-promociones ${isHovered ? 'hovered' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClickVermas}
              >
                <span className="icon"><MdDashboard /></span>
                <span className="text">Ver más</span>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Promociones;
