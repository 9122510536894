import React, { useState } from 'react';
import "../../assets/styles/BotonDescargar.css";

const BotonDescargar = ({ direccion, nombre }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleDownload = () => {
        setIsDownloading(true);
        setIsComplete(false);
        setIsDisabled(true);

        // Simula la descarga completada después de 2 segundos
        setTimeout(() => {
            setIsDownloading(false);
            setIsComplete(true);
            setIsDisabled(true);

            descargarArchivo(direccion, nombre);

            // Restablece el estado para volver al estado inicial después de 1 segundo
            setTimeout(() => {
                setIsDownloading(false);
                setIsComplete(false);
                setIsDisabled(false);
            }, 1000);
        }, 3000);
    };

    const descargarArchivo = (direccion, nombre) => {
        const direccionSegura = direccion.replace('http:', 'https:'); // Reemplaza 'http:' con 'https:'
      
        fetch(direccionSegura, {
          mode: 'cors' // Agrega la opción 'mode: cors' para permitir solicitudes CORS
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Error ' + response.status + ': ' + response.statusText);
            }
            return response.blob();
          })
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = nombre; // Nombre de archivo deseado
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            console.error(error);
          });
      };
      

    return (
        <button
            className={`button-descargar ${isDownloading ? 'button-downloading' : ''} ${isComplete ? 'button-complete' : ''}`}
            onClick={handleDownload}
            disabled={isDisabled}
        >
            {isDownloading ? 'Cargando...' : (isComplete ? 'Completo' : 'Descargar')}
        </button>
    );
};

export default BotonDescargar;
