import compartirIcono from '../../assets/images/sharedos.svg';
import '../../assets/styles/BotonCompartir.css';

import React from "react";

function BotonCompartir({ handleOpenPopup, imageUrl }) {
    const handleClickShare = () => {
      handleOpenPopup(imageUrl); // Pasamos la URL de la imagen al abrir el popup
    };
  
    return (
        <a
            className='icon-share'
            onClick={handleClickShare}
        >
        <img
            className='imgCompartir'
            src={compartirIcono}
            alt="Compartir"
        />
      </a>
    );
  }


export { BotonCompartir};