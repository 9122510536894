import React, { useEffect, useState } from 'react';
import { get } from '../../Api';
import "../../assets/styles/DetalleMoto.css";
import { useLocation, Link } from 'react-router-dom';
import { Accordion, Icon } from 'semantic-ui-react';
import BotonDescargar from '../../components/botones_descargar/BotonDescargar';
import IconDescargar from '../../components/botones_descargar/IconDescargar';
import { BotonVistaPreviaImagen } from '../../components/botones_vista_previa/BotonVistaPreviaImagen';
import { PopupVistaPreviaImagen } from '../../components/popups/PopupVistaPreviaImagen';
import { PopupCompartir } from '../../components/popups/PopupCompartir';
import { BotonCompartir } from '../../components/botones_compartir/BotonCompartir';
import { useParams } from 'react-router-dom';

function DetalleMoto() {
  const location = useLocation();
  const { idMotoParams} = useParams();
  const { nombreMotoParams} = useParams();
  const idMoto = (location.state?.idMoto) ? location.state?.idMoto : idMotoParams
  const nombreMoto = (location.state?.nombreMoto) ? location.state?.nombreMoto : nombreMotoParams
  const nombreMarca = nombreMotoParams.split('-')[0];
  const [moto, setMoto] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filtroActivo, setFiltroActivo] = useState('Todo');
  const [activeIndex, setActiveIndex] = useState(null);

  const [mostrarFichasTecnicas, setMostrarFichasTecnicas] = useState(5);
  const [mostrarFotos, setmostrarFotos] = useState(4);
  const [mostrarFotos360, setmostrarFotos360] = useState(5);
  const [mostrarVideos, setMostrarVideos] = useState(4);
  const [mostrarMaterialesPDV, setMostrarMaterialesPDV] = useState(5);
  const [mostrarImagenesPublicitarias, setMostrarImagenesPublicitarias] = useState(4);
  const [mostrarEditables, setMostrarEditables] = useState(5);
  const [mostrarDocumentos, setMostrarDocumentos] = useState(5);

  const filtrosBotones = ["Todo",
    "Fichas Técnicas",
    "Fotos",
    "Fotos360",
    "Videos",
    "Materiales PDV",
    "Imágenes Publicitarias",
    "Editables",
    "Documentos"]

  const handleFilterChange = (filter) => {
    setFiltroActivo(filter);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        const response = await get(`/getMoto/${idMoto}`);
        setMoto(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    // Aquí puedes agregar la lógica para realizar la búsqueda
    console.log('Realizando búsqueda...');
  };

  const handleAccordionClick = (e, { index }) => {
    const newIndex = activeIndex === index ? null : index;
    setActiveIndex(newIndex);
  };


  const totalFichasTecnicas = moto && moto[0]?.Datasheets ? Object.entries(moto[0].Datasheets).length : 0;
  const totalFotos = moto && moto[0]?.Fotos ? Object.entries(moto[0].Fotos).length : 0;
  const totalFotos360 = moto && moto[0]?.Fotos360 ? Object.entries(moto[0].Fotos360).length : 0;
  const totalVideos = moto && moto[0]?.Videos ? Object.entries(moto[0].Videos).length : 0;
  const totalMaterialesPDV = moto && moto[0]?.MaterialesPDV ? Object.entries(moto[0].MaterialesPDV).length : 0;
  const totalImagenesPublicitarias = moto && moto[0]?.ImagenesPublicitarias ? Object.entries(moto[0].ImagenesPublicitarias).length : 0;
  const totalEditables = moto && moto[0]?.Editables ? Object.entries(moto[0].Editables).length : 0;
  const totalDocumentos = moto && moto[0]?.Documentos ? Object.entries(moto[0].Documentos).length : 0;

  const [showPopup, setShowPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Estado para almacenar la URL de la imagen seleccionada


  const handleShowPopup = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowPopup(true);
  };
  const handleShowSharePopup = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowSharePopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleClosesharePopup = () => {
    setShowSharePopup(false);
  };

  return (
    <div >

      <div className='miga-de-pan-categoria'>
        <Link style={{ color: 'black' }} to='/'>Inicio</Link>
        {' > '}
        <Link style={{ color: 'black' }} to='/'>Marcas</Link>
        {' > '}
        <Link style={{ color: 'black' }} to={`/motos/${nombreMarca}`} state={{ nombreMarca: nombreMarca,  }}></Link>
        {' > '}
        <label>Moto</label>
      </div>

      <div className='div-detail' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div className='titulo-detalle'>Material {moto[0]?.Nombre}</div>

        {/* <div className='input-buscar-detail' style={{ width: '40%', border: 'none' }}>
          <Input
            style={{ width: '100%' }}
            icon={true}
            placeholder={`Buscar en ${moto[0]?.Nombre}...`}
          >
            <input />
            <Icon
              style={{ color: 'black', backgroundColor: '#f9c632' }}
              name="search"
              inverted
              square="true"
              link
              onClick={handleSearch}
            />
          </Input>
        </div> */}
      </div>

      {isLoading ? ( // Mostrar el loader mientras se cargan las marcas
        <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>Cargando material...</div>
      ) : (
        <>
          <div className='text subtitulo' >Filtra por tipo de archivo</div>
          <div className="filter-buttons-detalle" style={{ marginBottom: '50px' }}>
            {filtrosBotones.map((filter) => (
              <button
                key={filter}
                className={filter === filtroActivo ? 'active' : ''}
                onClick={() => handleFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>

          {(filtroActivo === 'Fichas Técnicas' || filtroActivo === 'Todo') && (
            <div>
              <div className='text subtitulo'>Fichas Técnicas</div>
              {moto && moto[0]?.Datasheets && Object.entries(moto[0].Datasheets).length > 0 ? (
                <>
                  {Object.entries(moto[0].Datasheets).slice(0, mostrarFichasTecnicas).map(([key, value]) => (
                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                      <div className="container-icono-detalles">
                        <Icon
                          className='download-icon'
                          style={{
                            color: '#706f6f',
                            marginTop: '16px',
                            float: 'left',
                            marginLeft: '10%',
                            fontSize: '26px',
                          }}
                          name='file'
                        />
                      </div>
                      
                      <div className="container-text-detalles">
                        <div className='conten-text-manual-carga'>
                          {screen.width <= 600 ? (
                            <label className='text-manual-carga'>
                              {value[3].substring(0, 20) + '...' + value[6]}
                            </label>
                          ) : (
                            <label className='text-manual-carga'>{value[3]}</label>
                          )}{' '}
                          <br />
                          <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span>{' '}
                          <span>{value[2]}</span>
                        </div>
                      </div>
                      <div className="container-iconos-detalles">
                        <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                      </div>
                      <div className="container-iconos-detalles">
                       
                        <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                      </div>
                      
                      <div className="container-iconos-detalles">
                        <IconDescargar direccion={value[1]} nombre={value[3]} />

                      </div>
                    </div>
                  ))}
                  {mostrarFichasTecnicas < totalFichasTecnicas && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarFichasTecnicas(mostrarFichasTecnicas + mostrarFichasTecnicas)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>
                  No hay archivos disponibles.
                </div>
              )}
            </div>
          )}

          {(filtroActivo === 'Fotos' || filtroActivo === 'Todo') && (
            <div>
              <div className='text subtitulo' style={{ marginBottom: 0 }}>Fotos</div>
              <div className="grid-detalle" >
                {moto && moto[0]?.Fotos && Object.entries(moto[0].Fotos).length > 0 ? (
                  <>
                    {Object.entries(moto[0].Fotos).slice(0, mostrarFotos).map(([key, value], index) => (
                      <div key={key} className="grid-item-search">
                        <div className='fecha-carga-material'>
                          <div className='nombre-archivo-material'>{value[3]}</div>
                          <div className='div-video-text-material'><label>Imagen</label>
                          </div>
                        </div>
                        <img src={value[1]} alt={`Imagen ${index + 1}`} />
                        <div className='datos-detalle'>
                          <div className='tecni-info'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                              <label style={{ color: '#666666' }}>Peso</label>
                              <span style={{ color: '#000' }}>{value[5]}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                              <label style={{ color: '#666666' }}>Resolución</label>
                              <span style={{ color: '#000' }}>{value[4]}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                              <label style={{ color: '#666666' }}>Formato</label>
                              <span style={{ color: '#000' }}>{value[6]}</span>
                            </div>
                          </div>
                          <div>
                            <BotonDescargar direccion={value[1]} nombre={value[3]} />
                          </div>
                          <div>
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                          </div>
                          <div>
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                )}
              </div>
              {showPopup && <PopupVistaPreviaImagen handleClose={handleClosePopup} imageUrl={selectedImageUrl} />}
              {showSharePopup && <PopupCompartir handleClose={handleClosesharePopup} imageUrl={selectedImageUrl} />}
              

              {mostrarFotos < totalFotos && (
                <div style={{ marginBottom: '30px' }}>
                  <button className='button-ver-mas-archivos'
                    onClick={() => setmostrarFotos(mostrarFotos + mostrarFotos)}
                  >
                    <span className="text">Ver más</span>
                  </button>
                </div>
              )}
            </div>
          )}

          {(filtroActivo === 'Fotos360' || filtroActivo === 'Todo') && (
            <div>
              <div className='text subtitulo' style={{ marginBottom: 5 }}>Fotos360</div>
              <div style={{ marginBottom: 30 }}>
                {moto && moto[0]?.Fotos360 && moto[0]?.Fotos360.length > 0 ? (
                  <>
                    <Accordion >
                      {moto[0].Fotos360.slice(0, mostrarFotos360).map((item, index) => (
                        <div key={item.idSKU}>
                          <Accordion.Title
                            style={{ background: 0 }}
                            active={activeIndex === item.idSKU}
                            index={item.idSKU}
                            onClick={handleAccordionClick}
                          >
                            <Icon name='dropdown' />
                            {item.nombre}
                          </Accordion.Title>
                          <Accordion.Content
                            style={{ background: 0 }}
                            active={activeIndex === item.idSKU}
                          >
                            <div className="grid-detalle">
                              {item.fotos.map((foto, index) => (
                                <div key={index} className="grid-item-search">
                                  <div className='fecha-carga-material'>
                                    <div className='nombre-archivo-material'>{foto.titulo}</div>
                                    <div className='div-video-text-material'><label>Imagen</label>
                                    </div>
                                  </div>
                                  <img src={foto.link} alt={`Imagen ${index + 1}`} />
                                  <div className='datos-detalle'>
                                    <div className='tecni-info'>
                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <label style={{ color: '#666666' }}>Peso</label>
                                        <span style={{ color: '#000' }}>{foto.tamano}</span>
                                      </div>
                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <label style={{ color: '#666666' }}>Resolución</label>
                                        <span style={{ color: '#000' }}>{foto.dimension}</span>
                                      </div>
                                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <label style={{ color: '#666666' }}>Formato</label>
                                        <span style={{ color: '#000' }}>{foto.extension}</span>
                                      </div>
                                    </div>
                                    <div>
                                      <BotonDescargar direccion={foto.link} nombre={foto.titulo} />
                                      <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={foto.link} />
                                      <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={foto.link} />

                                    </div>

                                  </div>
                                </div>
                              ))}
                            </div>
                          </Accordion.Content>
                        </div>
                      ))}
                    </Accordion>
                    {mostrarFotos360 < totalFotos360 && (
                      <div style={{ marginBottom: '30px' }}>
                        <button className='button-ver-mas-archivos'
                          onClick={() => setmostrarFotos360(mostrarFotos360 + mostrarFotos360)}
                        >
                          <span className="text">Ver más</span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                )}
              </div>
            </div>
          )}

          {
            (filtroActivo === 'Videos' || filtroActivo === 'Todo') && (
              <div>
                <div className='text subtitulo' style={{ marginBottom: 0 }}>Videos</div>
                <div className="grid-detalle" >
                  {moto && moto[0]?.Videos && Object.entries(moto[0].Videos).length > 0 ? (
                    <>
                      {Object.entries(moto[0].Videos).slice(0, mostrarVideos).map(([key, value], index) => (
                        <div key={key} className="grid-item-detalle">
                          <div className='fecha-carga-material'>
                            <div className='nombre-archivo-material'>{value[3]}</div>
                            <div className='div-video-text-material'><label>Video</label>
                            </div>
                          </div>
                          <video src={value[1]} />
                          <div className='datos'>
                            <div className='tecni-info'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Peso</label>
                                <span style={{ color: '#000' }}>{value[5]} </span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Resolución</label>
                                <span style={{ color: '#000' }}>{value[4]} </span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Formato</label>
                                <span style={{ color: '#000' }}>{value[6]} </span>
                              </div>
                            </div>
                            <div>
                              <BotonDescargar direccion={value[1]} nombre={value[3]} />
                            </div>
                            <div>
                              <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                            </div>
                            <div>
                              <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                </div>
                {mostrarVideos < totalVideos && (
                  <div style={{ marginBottom: '30px' }}>
                    <button className='button-ver-mas-archivos'
                      onClick={() => setMostrarVideos(mostrarVideos + mostrarVideos)}
                    >
                      <span className="text">Ver más</span>
                    </button>
                  </div>
                )}
              </div>
            )
          }

          {
            (filtroActivo === 'Materiales PDV' || filtroActivo === 'Todo') && (
              <div>
                <div className='text subtitulo' >Material punto de venta</div>
                {moto && moto[0]?.MaterialesPDV && Object.entries(moto[0].MaterialesPDV).length > 0 ? (
                  <>
                    {Object.entries(moto[0].MaterialesPDV).slice(0, mostrarMaterialesPDV).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))}
                    {mostrarMaterialesPDV < totalMaterialesPDV && (
                      <div style={{ marginBottom: '30px' }}>
                        <button className='button-ver-mas-archivos'
                          onClick={() => setMostrarMaterialesPDV(mostrarMaterialesPDV + mostrarMaterialesPDV)}
                        >
                          <span className="text">Ver más</span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                )}
              </div>
            )
          }

          {
            (filtroActivo === 'Imágenes Publicitarias' || filtroActivo === 'Todo') && (
              < div >
                <div className='text subtitulo' style={{ marginBottom: 0 }}>Imágenes Publicitarias</div>
                <div className="grid-detalle" >
                  {moto && moto[0]?.ImagenesPublicitarias && Object.entries(moto[0].ImagenesPublicitarias).length > 0 ? (
                    <>
                      {Object.entries(moto[0].ImagenesPublicitarias).slice(0, mostrarImagenesPublicitarias).map(([key, value], index) => (
                        <div key={key} className="grid-item-search">
                          <div className='fecha-carga-material'>
                            <div className='nombre-archivo-material'>{value[3]}</div>
                            <div className='div-video-text-material'><label>Imagen</label>
                            </div>
                          </div>
                          <img src={value[1]} alt={`Imagen ${index + 1}`} />
                          <div className='datos-detalle'>
                            <div className='tecni-info'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Peso</label>
                                <span style={{ color: '#000' }}>{value[5]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Resolución</label>
                                <span style={{ color: '#000' }}>{value[4]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Formato</label>
                                <span style={{ color: '#000' }}>{value[6]}</span>
                              </div>
                            </div>
                            <div>
                              <BotonDescargar direccion={value[1]} nombre={value[3]} />
                            </div>
                            <div>
                              <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                            </div>
                            <div>
                              <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                </div>
                {mostrarImagenesPublicitarias < totalImagenesPublicitarias && (
                  <div style={{ marginBottom: '30px' }}>
                    <button className='button-ver-mas-archivos'
                      onClick={() => setMostrarImagenesPublicitarias(mostrarImagenesPublicitarias + mostrarImagenesPublicitarias)}
                    >
                      <span className="text">Ver más</span>
                    </button>
                  </div>
                )}
              </div>
            )
          }

          {
            (filtroActivo === 'Editables' || filtroActivo === 'Todo') && (
              <div>
                <div className='text subtitulo' >Editables</div>
                {moto && moto[0]?.Editables && Object.entries(moto[0].Editables).length > 0 ? (
                  <>
                    {Object.entries(moto[0].Editables).slice(0, mostrarEditables).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))}
                    {mostrarEditables < totalEditables && (
                      <div style={{ marginBottom: '30px' }}>
                        <button className='button-ver-mas-archivos'
                          onClick={() => setMostrarEditables(mostrarEditables + mostrarEditables)}
                        >
                          <span className="text">Ver más</span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                )}
              </div>
            )
          }

          {
            (filtroActivo === 'Documentos' || filtroActivo === 'Todo') && (
              <div>
                <div className='text subtitulo' >Documentos</div>
                {moto && moto[0]?.Documentos && Object.entries(moto[0].Documentos).length > 0 ? (
                  <>
                    {Object.entries(moto[0].Documentos).slice(0, mostrarDocumentos).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))}
                    {mostrarDocumentos < totalDocumentos && (
                      <div style={{ marginBottom: '30px' }}>
                        <button className='button-ver-mas-archivos'
                          onClick={() => setMostrarDocumentos(mostrarDocumentos + mostrarDocumentos)}
                        >
                          <span className="text">Ver más</span>
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                )}
              </div>
            )
          }
        </>
      )
      }
    </div >
  );
};

export default DetalleMoto;