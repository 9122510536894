import React, { useEffect, useState } from 'react';
import { get } from '../../Api';
import { Link } from 'react-router-dom';
import "../../assets/styles/Material.css";
import BotonDescargar from '../../components/botones_descargar/BotonDescargar';
import IconDescargar from '../../components/botones_descargar/IconDescargar';
import { BotonVistaPreviaImagen } from '../../components/botones_vista_previa/BotonVistaPreviaImagen';
import { BotonCompartir } from '../../components/botones_compartir/BotonCompartir';
import { PopupCompartir } from '../../components/popups/PopupCompartir';
import { PopupVistaPreviaImagen } from '../../components/popups/PopupVistaPreviaImagen';
import { Icon, Loader } from 'semantic-ui-react';

function Material() {

  const [marcas, setMarcas] = useState([]);
  const [motos, setMotos] = useState([]);
  const [filtroActivo, setFiltroActivo] = useState('Todo');
  const [marcaSeleccionada, setMarcaSeleccionada] = useState(null);
  const [marca, setMarca] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMarca, setIsLoadingMarca] = useState(true);

  const [mostrarMotos, setMostrarMotos] = useState(4);
  const [mostrarFichasTecnicas, setMostrarFichasTecnicas] = useState(5);
  const [mostrarFotos, setmostrarFotos] = useState(4);
  const [mostrarVideos, setMostrarVideos] = useState(4);
  const [mostrarMaterialesPDV, setMostrarMaterialesPDV] = useState(5);
  const [mostrarImagenesPublicitarias, setMostrarImagenesPublicitarias] = useState(4);
  const [mostrarEditables, setMostrarEditables] = useState(5);
  const [mostrarDocumentos, setMostrarDocumentos] = useState(5);
  const [mostrarGigantografias, setMostrarGigantografias] = useState(5);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Estado para almacenar la URL de la imagen seleccionada
  const [showSharePopup, setShowSharePopup] = useState(false);


  const filtrosBotones = ["Todo",
    "Motos",
    "Fichas Técnicas",
    "Fotos",
    "Videos",
    "Materiales PDV",
    "Imágenes Publicitarias",
    "Editables",
    "Documentos",
    "Gigantografías"]


  useEffect(() => {
    const fetchData = async () => {
      try {
        window.scrollTo(0, 0);
        const response = await get('/getMarcas');
        setMarcas(response.data);
        setIsLoading(false);
        setIsLoadingMarca(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsLoadingMarca(false);
      }
    };

    fetchData();
  }, []);


  const obtenerMarca = async (id) => {
    setIsLoadingMarca(true);
    try {
      const response = await get(`/getMarca/${id}`);
      setMostrarFichasTecnicas(5);
      setmostrarFotos(4);
      setMostrarVideos(4);
      setMostrarMaterialesPDV(5);
      setMostrarImagenesPublicitarias(4);
      setMostrarEditables(5);
      setMostrarDocumentos(5);
      setMostrarGigantografias(5);
      setMarca(response.data); // Guardar la marca en el estado "marca"
      setIsLoadingMarca(false);
    } catch (error) {
      console.error(error);
      setIsLoadingMarca(false);
    }
  };


  const obtenerMotos = async (idMarca) => {
    try {
      window.scrollTo(0, 0);
      const response = await get(`/getMotos/brand${idMarca}`);
      setMostrarMotos(4);
      setMotos(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };





  const handleShowSharePopup = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowSharePopup(true);
  };

  const handleClosesharePopup = () => {
    setShowSharePopup(false);
  };


  const handleSearch = () => {
    // Aquí puedes agregar la lógica para realizar la búsqueda
    console.log('Realizando búsqueda...');
  };

  const handleFilterChange = (filter) => {
    setFiltroActivo(filter);
  };


  const handleMarca = (idMarca) => {
    setMarcaSeleccionada(idMarca);
    obtenerMarca(idMarca);
    obtenerMotos(idMarca);
  };

  const handleShowPopup = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const totalMotos = motos ? motos.length : 0;
  const totalFichasTecnicas = marca && marca[0]?.Datasheets ? Object.entries(marca[0].Datasheets).length : 0;
  const totalFotos = marca && marca[0]?.Fotos ? Object.entries(marca[0].Fotos).length : 0;
  const totalVideos = marca && marca[0]?.Videos ? Object.entries(marca[0].Videos).length : 0;
  const totalMaterialesPDV = marca && marca[0]?.MaterialesPDV ? Object.entries(marca[0].MaterialesPDV).length : 0;
  const totalImagenesPublicitarias = marca && marca[0]?.ImagenesPublicitarias ? Object.entries(marca[0].ImagenesPublicitarias).length : 0;
  const totalEditables = marca && marca[0]?.Editables ? Object.entries(marca[0].Editables).length : 0;
  const totalDocumentos = marca && marca[0]?.Documentos ? Object.entries(marca[0].Documentos).length : 0;
  const totalGigantografias = marca && marca[0]?.Gigantografias ? Object.entries(marca[0].Gigantografias).length : 0;

  return (
    <div className='principal'>

      <div className='text miga-de-pan'>Inicio</div>
      <div className='text titulo' >Material</div>


      <div className='text subtitulo' >Busca por marcas</div>
      {isLoading ? ( // Mostrar el loader mientras se cargan las marcas
        <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>Cargando marcas...</div>
      ) : marcas.length > 0 ? (
        <div className="grid-marcas">
          {marcas.map((marca, index) => (
            <div
              key={index}
              className={`grid-item-marca${marca.Id === marcaSeleccionada ? ' seleccionada' : ''}`}
              onClick={() => handleMarca(marca.Id)}
            >
              {marca.Logos && Object.keys(marca.Logos).length > 0 && (
                <img src={marca.Logos[Object.keys(marca.Logos)[0]][1]} alt={`Imagen ${index + 1}`} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className='text parrafo'>No hay marcas disponibles.</div>
      )}

      {!isLoading && marcas.length > 0 ? (
        <>

          <div className='text subtitulo' >Filtra por tipo de archivo</div>
          <div className="filter-buttons-materiales" style={{ marginBottom: '50px' }}>
            {filtrosBotones.map((filter) => (
              <button
                key={filter}
                className={filter === filtroActivo ? 'active' : ''}
                onClick={() => handleFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>

          {/* <div className='div-material' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
            <div className='input-buscar-material' style={{ width: '40%', border: 'none' }}>
              <Input
                style={{ width: '100%' }}
                icon={true}
                placeholder="Buscar en esta categoría..."
              >
                <input />
                <Icon
                  style={{ color: 'black', backgroundColor: '#f9c632' }}
                  name="search"
                  inverted
                  square="true"
                  link
                  onClick={handleSearch}
                />
              </Input>
            </div>
          </div> */}

          {isLoadingMarca ? (
            <Loader active
              className="custom-loader"
              inline="centered"
            >
              Cargando...
            </Loader>
          ) : (
            <>
              {(filtroActivo === 'Motos' || filtroActivo === 'Todo') && (
                <div style={{ marginBottom: '40px' }}>
                  <div className='text subtitulo' style={{ marginBottom: 0 }} >Motos</div>
                  <div className='grid-categoria'>
                    {motos.length > 0 ? (
                      motos.slice(0, mostrarMotos).map((moto, index) => (
                        <div key={index} className='grid-item-categoria'>
                          <div className='div-marca'>
                            <label className='titulo-marca'>{marca[0].Nombre}</label>
                          </div>
                          <div>
                            {moto.Fotos && Object.keys(moto.Fotos).length > 0 && (
                              <img src={moto.Fotos[Object.keys(moto.Fotos)[0]][1]} alt={`Imagen ${index + 1}`} />
                            )}
                          </div>
                          <div className='div-ver-mas'>
                            <div className='categoria-info'>
                              <label>{moto.Nombre}</label>
                              <br />
                              <label style={{ fontSize: '10px' }}>Modelo:  {moto.Modelo}</label>
                            </div>
                            <div>
                              <Link key={index} to={`/detalle_moto/${moto.Id}/${moto.Nombre.toLowerCase().replace(/\s+/g, '-')}`} state={{ idMoto: moto.Id, nombreMarca: marca[0].Nombre, idMarca: marca[0].Id }}>
                                <button color='yellow' className='button-ver-mas1'>
                                  Ver más
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>
                        No hay motos disponibles.
                      </div>
                    )}
                  </div>
                  {mostrarMotos < totalMotos && (
                    <div style={{ marginBottom: '30px', marginTop: '20px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarMotos(mostrarMotos + mostrarMotos)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Fichas Técnicas' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' >Fichas Técnicas</div>
                  {marca && marca[0]?.Datasheets && Object.entries(marca[0].Datasheets).length > 0 ? (
                    Object.entries(marca[0].Datasheets).slice(0, mostrarFichasTecnicas).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)}
                            <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                  {mostrarFichasTecnicas < totalFichasTecnicas && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarFichasTecnicas(mostrarFichasTecnicas + mostrarFichasTecnicas)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Fotos' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' style={{ marginBottom: 0 }}>Fotos</div>
                  <div className="grid-detalle" >
                    {marca && marca[0]?.Fotos && Object.entries(marca[0].Fotos).length > 0 ? (
                      Object.entries(marca[0].Fotos).slice(0, mostrarFotos).map(([key, value], index) => (
                        <div key={key} className="grid-item-search">
                          <div className='fecha-carga-material'>
                            <div className='nombre-archivo-material'>{value[3]}</div>
                            <div className='div-video-text-material'><label>Imagen</label>
                            </div>
                          </div>
                          <img src={value[1]} alt={`Imagen ${index + 1}`} />
                          <div className='datos-detalle'>
                            <div className='tecni-info'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Peso</label>
                                <span style={{ color: '#000' }}>{value[5]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Resolución</label>
                                <span style={{ color: '#000' }}>{value[4]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Formato</label>
                                <span style={{ color: '#000' }}>{value[6]}</span>
                              </div>
                            </div>
                            <div>
                              <BotonDescargar direccion={value[1]} nombre={value[3]} />
                            </div>
                            <div>
                              <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                            </div>
                            <div>
                              <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                    )}
                  </div>
                  {mostrarFotos < totalFotos && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setmostrarFotos(mostrarFotos + mostrarFotos)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Videos' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' style={{ marginBottom: 0 }}>Videos</div>
                  <div className="grid-detalle" >
                    {marca && marca[0]?.Videos && Object.entries(marca[0].Videos).length > 0 ? (
                      Object.entries(marca[0].Videos).slice(0, mostrarVideos).map(([key, value], index) => (
                        <div key={key} className="grid-item-detalle">
                          <div className='fecha-carga-material'>
                            <div className='nombre-archivo-material'>{value[3]}</div>
                            <div className='div-video-text-material'><label>Video</label>
                            </div>
                          </div>
                          <video src={value[1]} />
                          <div className='datos'>
                            <div className='tecni-info'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Peso</label>
                                <span style={{ color: '#000' }}>{value[5]} </span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Resolución</label>
                                <span style={{ color: '#000' }}>{value[4]} </span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Formato</label>
                                <span style={{ color: '#000' }}>{value[6]} </span>
                              </div>
                            </div>
                            <div>
                              <BotonDescargar direccion={value[1]} nombre={value[3]} />
                            </div>
                            <div>
                              <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                            </div>
                            <div>
                              <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                    )}
                  </div>
                  {mostrarVideos < totalVideos && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarVideos(mostrarVideos + mostrarVideos)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Materiales PDV' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' >Material punto de venta</div>
                  {marca && marca[0]?.MaterialesPDV && Object.entries(marca[0].MaterialesPDV).length > 0 ? (
                    Object.entries(marca[0].MaterialesPDV).slice(0, mostrarMaterialesPDV).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                  {mostrarMaterialesPDV < totalMaterialesPDV && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarMaterialesPDV(mostrarMaterialesPDV + mostrarMaterialesPDV)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Imágenes Publicitarias' || filtroActivo === 'Todo') && (
                < div >
                  <div className='text subtitulo' style={{ marginBottom: 0 }}>Imágenes Publicitarias</div>
                  <div className="grid-detalle" >
                    {marca && marca[0]?.ImagenesPublicitarias && Object.entries(marca[0].ImagenesPublicitarias).length > 0 ? (
                      Object.entries(marca[0].ImagenesPublicitarias).slice(0, mostrarImagenesPublicitarias).map(([key, value], index) => (
                        <div key={key} className="grid-item-search">
                          <div className='fecha-carga-material'>
                            <div className='nombre-archivo-material'>{value[3]}</div>
                            <div className='div-video-text-material'><label>Imagen</label>
                            </div>
                          </div>
                          <img src={value[1]} alt={`Imagen ${index + 1}`} />
                          <div className='datos-detalle'>
                            <div className='tecni-info'>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Peso</label>
                                <span style={{ color: '#000' }}>{value[5]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Resolución</label>
                                <span style={{ color: '#000' }}>{value[4]}</span>
                              </div>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <label style={{ color: '#666666' }}>Formato</label>
                                <span style={{ color: '#000' }}>{value[6]}</span>
                              </div>
                            </div>
                            <div>
                              <BotonDescargar direccion={value[1]} nombre={value[3]} />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                    )}
                  </div>
                  {mostrarImagenesPublicitarias < totalImagenesPublicitarias && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarImagenesPublicitarias(mostrarImagenesPublicitarias + mostrarImagenesPublicitarias)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Editables' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' >Editables</div>
                  {marca && marca[0]?.Editables && Object.entries(marca[0].Editables).length > 0 ? (
                    Object.entries(marca[0].Editables).slice(0, mostrarEditables).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                  {mostrarEditables < totalEditables && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarEditables(mostrarEditables + mostrarEditables)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Documentos' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' >Documentos</div>
                  {marca && marca[0]?.Documentos && Object.entries(marca[0].Documentos).length > 0 ? (
                    Object.entries(marca[0].Documentos).slice(0, mostrarDocumentos).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                  {mostrarDocumentos < totalDocumentos && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarDocumentos(mostrarDocumentos + mostrarDocumentos)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}

              {(filtroActivo === 'Gigantografías' || filtroActivo === 'Todo') && (
                <div>
                  <div className='text subtitulo' >Gigantografías</div>
                  {marca && marca[0]?.Gigantografias && Object.entries(marca[0].Gigantografias).length > 0 ? (
                    Object.entries(marca[0].Gigantografias).slice(0, mostrarGigantografias).map(([key, value]) => (
                      <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                        <div className="container-icono-detalles">
                          <Icon
                            className='download-icon'
                            style={{
                              color: '#706f6f',
                              marginTop: '16px',
                              float: 'left',
                              marginLeft: '10%',
                              fontSize: '26px'
                            }}
                            name="file"
                          />
                        </div>
                        <div className="container-text-detalles">
                          <div className='conten-text-manual-carga'>
                            {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                            ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                          </div>
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                        </div>
                        <div className="container-iconos-detalles">
                          <IconDescargar direccion={value[1]} nombre={value[3]} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                  )}
                  {mostrarGigantografias < totalGigantografias && (
                    <div style={{ marginBottom: '30px' }}>
                      <button className='button-ver-mas-archivos'
                        onClick={() => setMostrarGigantografias(mostrarGigantografias + mostrarGigantografias)}
                      >
                        <span className="text">Ver más</span>
                      </button>
                    </div>
                  )}
                </div>
              )}
               {showPopup && <PopupVistaPreviaImagen handleClose={handleClosePopup} imageUrl={selectedImageUrl} />}
               {showSharePopup && <PopupCompartir handleClose={handleClosesharePopup} imageUrl={selectedImageUrl} />}

            </>
          )}

        </>
      ) : (null)}
    </div >
  );
};

export default Material;