import React from "react";
import "./assets/styles/App.css";
import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SideNavLeft from "./components/sidenav/SideNavLeft";
import Home from "./pages/home/Home";
import Motos from "./pages/material_motos/Motos";
import Material from "./pages/material_marcas/Material";
import DetalleMoto from "./pages/material_motos/DetalleMoto";
import Promociones from "./pages/promociones/Promociones";
import DetallePromocion from "./pages/promociones/DetallePromocion";
import Corporativos from "./pages/corporativo/Corporativos";
import DetalleCorporativo from "./pages/corporativo/DetalleCorporativo";
import ResultadoAbierto from "./pages/resultados_busqueda/ResultadoAbierto";
import ResultadoCerrado from "./pages/resultados_busqueda/ResultadoCerrado";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <SideNavLeft>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/motos/:idMarcaParams/:nombreMarcaParams' element={<Motos />} />
          <Route path='/material' element={<Material />} />
          <Route path='/detalle_moto/:idMotoParams/:nombreMotoParams' element={<DetalleMoto />} />
          <Route path='/promociones' element={<Promociones />} />
          <Route path='/detalle_promocion/:idPromocionParams/:nombrePromocionParams' element={<DetallePromocion />} />
          <Route path='/corporativos' element={<Corporativos />} />
          <Route path='/detalle_corporativo/:idCorporativoParams/:nombreCorporativoParams' element={<DetalleCorporativo />} />
          <Route path='/resultado_busqueda_a' element={<ResultadoAbierto />} />
          <Route path='/resultado_busqueda_c' element={<ResultadoCerrado />} />
        </Routes>
      </SideNavLeft>
    </BrowserRouter>
  );
}

export default App;
