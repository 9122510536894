import vistaPreviaIcono from '../../assets/images/eye.svg';
import '../../assets/styles/BotonVistaPreviaImagen.css';

import React from "react";

function BotonVistaPreviaImagen({ handleOpenPopup, imageUrl }) {
    const handleClick = () => {
      handleOpenPopup(imageUrl); // Pasamos la URL de la imagen al abrir el popup
    };
  
    return (
      <a
        className='icon-preview'
        onClick={handleClick}
      >
      <img
        src={vistaPreviaIcono}
        alt="Vista Previa"
      />
      </a>
    );
  }


export { BotonVistaPreviaImagen };