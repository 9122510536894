import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import "../../assets/styles/Corporativo.css";
import { Icon } from 'semantic-ui-react';
import BotonDescargar from '../../components/botones_descargar/BotonDescargar';
import IconDescargar from '../../components/botones_descargar/IconDescargar';
import { BotonVistaPreviaImagen } from '../../components/botones_vista_previa/BotonVistaPreviaImagen';
import { PopupVistaPreviaImagen } from '../../components/popups/PopupVistaPreviaImagen';
import { PopupCompartir } from '../../components/popups/PopupCompartir';
import { BotonCompartir } from '../../components/botones_compartir/BotonCompartir';
import { get } from '../../Api';
import { useParams } from 'react-router-dom';

function DetalleCorporativo() {
    const location = useLocation();
    const {idCorporativoParams, nombreCorporativoParams} = useParams();
    console.log("corporativo param id: "+idCorporativoParams)
    const idCorporativo = (location.state?.idCorporativo ) ? location.state?.idCorporativo : idCorporativoParams//recibimos el idCorporativo
    const nombreCorporativo = location.state?.nombreCorporativo ? location.state?.nombreCorporativo : nombreCorporativoParams
    const [filtroActivo, setFiltroActivo] = useState('Todo');
    const [corporativo, setCorporativo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [mostrarFichasTecnicas, setMostrarFichasTecnicas] = useState(5);
    const [mostrarFotos, setmostrarFotos] = useState(4);
    const [mostrarVideos, setMostrarVideos] = useState(4);
    const [mostrarMaterialesPDV, setMostrarMaterialesPDV] = useState(5);
    const [mostrarImagenesPublicitarias, setMostrarImagenesPublicitarias] = useState(4);
    const [mostrarEditables, setMostrarEditables] = useState(5);
    const [mostrarDocumentos, setMostrarDocumentos] = useState(5);
    const [mostrarGigantografias, setMostrarGigantografias] = useState(5);
    const [showPopup, setShowPopup] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Estado para almacenar la URL de la imagen seleccionada

    const filtrosBotones = ["Todo",
        "Fichas Técnicas",
        "Fotos",
        "Videos",
        "Materiales PDV",
        "Imágenes Publicitarias",
        "Editables",
        "Documentos",
        "Gigantografías"]


    // useEffect para obtener corporativo
    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);
                const response = await get(`/getCorporativo/${idCorporativo}`);
                setCorporativo(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleSearch = () => {
        // Aquí puedes agregar la lógica para realizar la búsqueda
        console.log('Realizando búsqueda...');
    };

    const handleFilterChange = (filter) => {
        setFiltroActivo(filter);
    };


  
  
    const handleShowPopup = (imageUrl) => {
      setSelectedImageUrl(imageUrl);
      setShowPopup(true);
    };
    const handleShowSharePopup = (imageUrl) => {
      setSelectedImageUrl(imageUrl);
      setShowSharePopup(true);
    };
  
    const handleClosePopup = () => {
      setShowPopup(false);
    };
    const handleClosesharePopup = () => {
      setShowSharePopup(false);
    };


    const totalFichasTecnicas = corporativo && corporativo[0]?.Datasheets ? Object.entries(corporativo[0].Datasheets).length : 0;
    const totalFotos = corporativo && corporativo[0]?.Fotos ? Object.entries(corporativo[0].Fotos).length : 0;
    const totalVideos = corporativo && corporativo[0]?.Videos ? Object.entries(corporativo[0].Videos).length : 0;
    const totalMaterialesPDV = corporativo && corporativo[0]?.MaterialesPDV ? Object.entries(corporativo[0].MaterialesPDV).length : 0;
    const totalImagenesPublicitarias = corporativo && corporativo[0]?.ImagenesPublicitarias ? Object.entries(corporativo[0].ImagenesPublicitarias).length : 0;
    const totalEditables = corporativo && corporativo[0]?.Editables ? Object.entries(corporativo[0].Editables).length : 0;
    const totalDocumentos = corporativo && corporativo[0]?.Documentos ? Object.entries(corporativo[0].Documentos).length : 0;
    const totalGigantografias = corporativo && corporativo[0]?.Gigantografias ? Object.entries(corporativo[0].Gigantografias).length : 0;


    return (
        <div className='principal'>

            <div className='miga-de-pan-categoria'>
                <Link style={{ color: 'black' }} to='/'>Inicio</Link>
                {' > '}
                <Link style={{ color: 'black' }} to='/corporativos'>Corporativos</Link>
                {' > '}
                <label>Corporativo</label>
            </div>

            <div className='text titulo' >{nombreCorporativo}</div>

            {isLoading ? (
                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>Cargando archivos...</div>
            ) : corporativo.length > 0 ? (null)
                : (
                    <div className='text parrafo'>No hay archivos disponibles.</div>
                )}


            {!isLoading && corporativo.length > 0 ? (
                <>

                    <div className='text subtitulo' >Filtra por tipo de archivo</div>
                    <div className="filter-buttons-corporativo" style={{ marginBottom: '50px' }}>
                        {filtrosBotones.map((filter) => (
                            <button
                                key={filter}
                                className={filter === filtroActivo ? 'active' : ''}
                                onClick={() => handleFilterChange(filter)}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>

                    {/* <div className='div-corporate' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
            <div className='input-buscar-corporativo' style={{ width: '50%', border: 'none' }}>
              <Input
                style={{ width: '100%' }}
                icon={true}
                placeholder="Buscar en esta categoría..."
              >
                <input />
                <Icon
                  style={{ color: 'black', backgroundColor: '#f9c632' }}
                  name="search"
                  inverted
                  square="true"
                  link
                  onClick={handleSearch}
                />
              </Input>
            </div>
          </div> */}

                    {(filtroActivo === 'Fichas Técnicas' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Fichas Técnicas</div>
                            {corporativo && corporativo[0]?.Datasheets && Object.entries(corporativo[0].Datasheets).length > 0 ? (
                                Object.entries(corporativo[0].Datasheets).slice(0, mostrarFichasTecnicas).map(([key, value]) => (
                                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                        <div style={{ width: '7%' }}>
                                            <Icon
                                                className='download-icon'
                                                style={{
                                                    color: '#706f6f',
                                                    marginTop: '16px',
                                                    float: 'left',
                                                    marginLeft: '10%',
                                                    fontSize: '26px'
                                                }}
                                                name="file"
                                            />
                                        </div>
                                        <div style={{ width: '80.5%' }}>
                                            <div className='conten-text-manual-carga'>
                                                {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                            </div>
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div  className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                        <div  className="container-iconos-detalles">
                                            <IconDescargar direccion={value[1]} nombre={value[3]} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                            {mostrarFichasTecnicas < totalFichasTecnicas && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarFichasTecnicas(mostrarFichasTecnicas + mostrarFichasTecnicas)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Fotos' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Fotos</div>
                            <div className="grid-container" >
                                {corporativo && corporativo[0]?.Fotos && Object.entries(corporativo[0].Fotos).length > 0 ? (
                                    Object.entries(corporativo[0].Fotos).slice(0, mostrarFotos).map(([key, value], index) => (
                                        <div key={key} className="grid-item-corporate">
                                            <img src={value[1]} alt={`Imagen ${index + 1}`} />
                                            <div className='div-video-text-corporativo'><label>Imagen</label>
                                            </div>
                                            <div className='datos'>
                                                <div className='fecha-carga'>
                                                    <label className='nombre-archivo'>{value[3]}</label><br />
                                                    <label style={{ color: '#666666', fontSize: '11px' }}>Fecha de carga: <span style={{ color: '#000' }}>{value[2]}</span></label>
                                                </div>
                                                <div className='tecni-info'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Peso</label>
                                                        <span style={{ color: '#000' }}>{value[5]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Resolución</label>
                                                        <span style={{ color: '#000' }}>{value[4]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Formato</label>
                                                        <span style={{ color: '#000' }}>{value[6]}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BotonDescargar direccion={value[1]} nombre={value[3]} />
                                                </div>
                                                <div>
                                                    <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                                                </div>
                                                <div>
                                                    <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarFotos < totalFotos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setmostrarFotos(mostrarFotos + mostrarFotos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Videos' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Videos</div>
                            <div className="grid-container" >
                                {corporativo && corporativo[0]?.Videos && Object.entries(corporativo[0].Videos).length > 0 ? (
                                    Object.entries(corporativo[0].Videos).slice(0, mostrarVideos).map(([key, value], index) => (
                                        <div key={key} className="grid-item-corporate">
                                            <video src={value[1]} />
                                            <div className='div-video-text-corporativo'><label>Video</label>
                                            </div>
                                            <div className='datos'>
                                                <div className='fecha-carga'>
                                                    <label className='nombre-archivo'>{value[3]}</label><br />
                                                    <label style={{ color: '#666666', fontSize: '11px' }}>Fecha de carga: <span style={{ color: '#000' }}>{value[2]}</span></label>
                                                </div>
                                                <div className='tecni-info'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Peso</label>
                                                        <span style={{ color: '#000' }}>{value[5]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Resolución</label>
                                                        <span style={{ color: '#000' }}>{value[4]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Formato</label>
                                                        <span style={{ color: '#000' }}>{value[6]}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BotonDescargar direccion={value[1]} nombre={value[3]} />
                                                </div>
                                                <div>
                                                    <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                                                </div>
                                                <div>
                                                    <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarVideos < totalVideos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarVideos(mostrarVideos + mostrarVideos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Materiales PDV' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Material punto de venta</div>
                            {corporativo && corporativo[0]?.MaterialesPDV && Object.entries(corporativo[0].MaterialesPDV).length > 0 ? (
                                Object.entries(corporativo[0].MaterialesPDV).slice(0, mostrarMaterialesPDV).map(([key, value]) => (
                                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                        <div className="container-icono-detalles">
                                            <Icon
                                                className='download-icon'
                                                style={{
                                                    color: '#706f6f',
                                                    marginTop: '16px',
                                                    float: 'left',
                                                    marginLeft: '10%',
                                                    fontSize: '26px'
                                                }}
                                                name="file"
                                            />
                                        </div>
                                        <div className="container-text-detalles">
                                            <div className='conten-text-manual-carga'>
                                                {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                            </div>
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <IconDescargar direccion={value[1]} nombre={value[3]} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                            {mostrarMaterialesPDV < totalMaterialesPDV && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarMaterialesPDV(mostrarMaterialesPDV + mostrarMaterialesPDV)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Imágenes Publicitarias' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Imágenes Publicitarias</div>
                            <div className="grid-container" >
                                {corporativo && corporativo[0]?.ImagenesPublicitarias && Object.entries(corporativo[0].ImagenesPublicitarias).length > 0 ? (
                                    Object.entries(corporativo[0].ImagenesPublicitarias).slice(0, mostrarImagenesPublicitarias).map(([key, value], index) => (
                                        <div key={key} className="grid-item-corporate">
                                            <img src={value[1]} alt={`Imagen ${index + 1}`} />
                                            <div className='div-video-text-corporativo'><label>Imagen</label>
                                            </div>
                                            <div className='datos'>
                                                <div className='fecha-carga'>
                                                    <label className='nombre-archivo'>{value[3]}</label><br />
                                                    <label style={{ color: '#666666', fontSize: '11px' }}>Fecha de carga: <span style={{ color: '#000' }}>{value[2]}</span></label>
                                                </div>
                                                <div className='tecni-info'>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Peso</label>
                                                        <span style={{ color: '#000' }}>{value[5]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Resolución</label>
                                                        <span style={{ color: '#000' }}>{value[4]}</span>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                        <label style={{ color: '#666666' }}>Formato</label>
                                                        <span style={{ color: '#000' }}>{value[6]}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <BotonDescargar direccion={value[1]} nombre={value[3]} />
                                                </div>
                                                <div>
                                                    <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />       
                                                </div>
                                                <div>
                                                    <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarImagenesPublicitarias < totalImagenesPublicitarias && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarImagenesPublicitarias(mostrarImagenesPublicitarias + mostrarImagenesPublicitarias)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Editables' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Editables</div>
                            {corporativo && corporativo[0]?.Editables && Object.entries(corporativo[0].Editables).length > 0 ? (
                                Object.entries(corporativo[0].Editables).slice(0, mostrarEditables).map(([key, value]) => (
                                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                        <div style={{ width: '7%' }}>
                                            <Icon
                                                className='download-icon'
                                                style={{
                                                    color: '#706f6f',
                                                    marginTop: '16px',
                                                    float: 'left',
                                                    marginLeft: '10%',
                                                    fontSize: '26px'
                                                }}
                                                name="file"
                                            />
                                        </div>
                                        <div style={{ width: '80.5%' }}>
                                            <div className='conten-text-manual-carga'>
                                                {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                            </div>
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <IconDescargar direccion={value[1]} nombre={value[3]} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                            {mostrarEditables < totalEditables && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarEditables(mostrarEditables + mostrarEditables)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Documentos' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Documentos</div>
                            {corporativo && corporativo[0]?.Documentos && Object.entries(corporativo[0].Documentos).length > 0 ? (
                                Object.entries(corporativo[0].Documentos).slice(0, mostrarDocumentos).map(([key, value]) => (
                                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                        <div style={{ width: '7%' }}>
                                            <Icon
                                                className='download-icon'
                                                style={{
                                                    color: '#706f6f',
                                                    marginTop: '16px',
                                                    float: 'left',
                                                    marginLeft: '10%',
                                                    fontSize: '26px'
                                                }}
                                                name="file"
                                            />
                                        </div>
                                        <div style={{ width: '80.5%' }}>
                                            <div className='conten-text-manual-carga'>
                                                {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                            </div>
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <IconDescargar direccion={value[1]} nombre={value[3]} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                            {mostrarDocumentos < totalDocumentos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarDocumentos(mostrarDocumentos + mostrarDocumentos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroActivo === 'Gigantografías' || filtroActivo === 'Todo') && (
                        <div>
                            <div className='text subtitulo' >Gigantografías</div>
                            {corporativo && corporativo[0]?.Gigantografias && Object.entries(corporativo[0].Gigantografias).length > 0 ? (
                                Object.entries(corporativo[0].Gigantografias).slice(0, mostrarGigantografias).map(([key, value]) => (
                                    <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={key}>
                                        <div style={{ width: '7%' }}>
                                            <Icon
                                                className='download-icon'
                                                style={{
                                                    color: '#706f6f',
                                                    marginTop: '16px',
                                                    float: 'left',
                                                    marginLeft: '10%',
                                                    fontSize: '26px'
                                                }}
                                                name="file"
                                            />
                                        </div>
                                        <div style={{ width: '80.5%' }}>
                                            <div className='conten-text-manual-carga'>
                                                {screen.width <= 600 ? (<label className='text-manual-carga'>{value[3].substring(0, 20) + '...' + value[6]}</label>
                                                ) : (<label className='text-manual-carga'>{value[3]}</label>)} <br />
                                                <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{value[2]}</span>
                                            </div>
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonVistaPreviaImagen handleOpenPopup={handleShowPopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <BotonCompartir handleOpenPopup={handleShowSharePopup} imageUrl={value[1]} />
                                        </div>
                                        <div className="container-iconos-detalles">
                                            <IconDescargar direccion={value[1]} nombre={value[3]} />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                            {mostrarGigantografias < totalGigantografias && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarGigantografias(mostrarGigantografias + mostrarGigantografias)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                        {showPopup && <PopupVistaPreviaImagen handleClose={handleClosePopup} imageUrl={selectedImageUrl} />}
                        {showSharePopup && <PopupCompartir handleClose={handleClosesharePopup} imageUrl={selectedImageUrl} />}
                </>
            ) : (null)}



        </div>
    );
};

export default DetalleCorporativo;