import React, { useState, useEffect } from 'react';
import '../../assets/styles/PopupVistaPreviaImagen.css';
import cerrarIcono from '../../assets/images/cerrar.svg';

const PopupVistaPreviaImagen = ({ imageUrl, handleClose }) => {
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setIsImage(true);
    };
    image.onerror = () => {
      setIsImage(false);
    };
    image.src = imageUrl;
  }, [imageUrl]);

  return (
    <div className="contenedor-popup" >
      <div className='fondo-oscuro' onClick={handleClose}></div>
      <div className="contenido-popup">
        <button className="boton-cerrar" onClick={handleClose}><img className='imgCerrarPopup' src={cerrarIcono} /></button>
        {isImage ? (
          <img src={imageUrl} alt="Imagen vista previa" />
        ) : (
          <div>
            <p>Vista previa no disponible</p>
            <p>Enlace al recurso: <a href={imageUrl} target="_blank" rel="noopener noreferrer">Aquí</a></p>
          </div>
        )}
      </div>
    </div>
  );
};

export { PopupVistaPreviaImagen };
