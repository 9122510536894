// Api.js
import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://ruta.auteco.com.co/api/wp-json/auteco/catalog',
    //baseURL: 'https://danielc338.sg-host.com/wp-json/auteco/catalog',
    //baseURL: 'http://catalogoauteco.test/wp-json/auteco/catalog',
});

export const get = (path, params) => instance.get(path, { params });
export const post = (path, data) => instance.post(path, data);
export const put = (path, data) => instance.put(path, data);
export const del = (path) => instance.delete(path);
