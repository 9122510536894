import React, { useState } from 'react';
import "../../assets/styles/ResultadoCerrado.css";
import { Input, Loader, Icon } from 'semantic-ui-react'
import { post } from '../../Api';
import BotonDescargar from '../../components/botones_descargar/BotonDescargar';
import IconDescargar from '../../components/botones_descargar/IconDescargar';

function ResultadoCerrado() {

    const [isLoading, setIsLoading] = useState(false);
    const [filtroSeleccionado, setFiltroSeleccionado] = useState('default');
    const [resultados, setResultados] = useState([]);
    const [imagenes, setImagenes] = useState([]);
    const [videos, setVideos] = useState([]);
    const [archivos, setArchivos] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Estado para almacenar el texto de búsqueda

    const [mostrarImagenes, setMostrarImagenes] = useState(4);
    const [mostrarVideos, setMostrarVideos] = useState(4);
    const [mostrarArchivos, setMostrarArchivos] = useState(5);

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            setIsLoading(true); // Mostrar el loading

            // Realizar la petición POST con la palabra de búsqueda utilizando Axios
            post('/buscarArchivos', { buscar: searchTerm })
                .then((response) => {
                    // Manejar la respuesta del servidor y actualizar el estado 'resultados', 'imagenes', 'videos', 'archivos', etc.
                    setIsLoading(false);
                    const data = response.data;

                    // Ordenar los resultados por orden alfabético según el título
                    data.sort((a, b) => {
                        const tituloA = a.titulo.toLowerCase();
                        const tituloB = b.titulo.toLowerCase();
                        if (tituloA < tituloB) {
                            return -1;
                        }
                        if (tituloA > tituloB) {
                            return 1;
                        }
                        return 0;
                    });

                    setResultados(data);

                    // Filtrar los resultados por tipo
                    const imagenesFiltradas = data.filter((item) => item.tipo === 'imagen');
                    setImagenes(imagenesFiltradas);
                    setMostrarImagenes(4);

                    const videosFiltrados = data.filter((item) => item.tipo === 'video');
                    setVideos(videosFiltrados);
                    setMostrarVideos(4);

                    const archivosFiltrados = data.filter((item) => item.tipo === 'archivo');
                    setArchivos(archivosFiltrados);
                    setMostrarArchivos(5);
                })
                .catch((error) => {
                    setIsLoading(false);
                    // Manejar el error de la petición
                    console.error('Error en la búsqueda:', error);
                });
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleFiltroSeleccionado = (event) => {
        setFiltroSeleccionado(event.target.value);
    };

    const totalImagenes = imagenes ? imagenes.length : 0;
    const totalVideos = videos ? videos.length : 0;
    const totalArchivos = archivos ? archivos.length : 0;

    return (

        <div>
            <div className='text titulo' style={{ marginBottom: '40px' }}>Buscar en todo el sitio</div>

            <div className='div-search' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
                <div className='input-buscar-promo' style={{ width: '40%', border: 'none' }}>
                    <Input style={{ width: '100%' }} icon={true} placeholder={`Buscar archivos...`}>
                        <input
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <Icon
                            style={{ color: 'black', backgroundColor: '#f9c632' }}
                            name="search"
                            inverted
                            square="true"
                            link
                            onClick={handleSearch}
                        />
                    </Input>
                </div>

                <div className='div-selector-orden' style={{ width: '25%' }}>
                    <select value={filtroSeleccionado} className="selector" onChange={handleFiltroSeleccionado}>
                        <option value='default'>Filtrar por Tipo</option>
                        <option value='imagenes'>Imágenes</option>
                        <option value='videos'>Videos</option>
                        <option value='archivos'>Archivos</option>
                    </select>
                </div>
            </div>

            {/* ...otros elementos JSX... */}
            {isLoading ? (
                <Loader active
                    className="custom-loader"
                    inline="centered"
                >
                    Buscando...
                </Loader>

            ) : (
                <>
                    {(filtroSeleccionado === 'imagenes' || filtroSeleccionado === 'default') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Imágenes</div>
                            <div className="grid-detalle">
                                {imagenes && imagenes.slice(0, mostrarImagenes).map((imagen, index) => (
                                    <div key={index} className="grid-item-search">
                                        <div className='fecha-carga-material'>
                                            <div className='nombre-archivo-material'>{imagen.titulo}</div>
                                            <div className='div-video-text-material'><label>Imagen</label></div>
                                        </div>
                                        <img src={imagen.url} alt={`Imagen ${index + 1}`} />
                                        <div className='datos-detalle'>
                                            <div className='tecni-info'>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Peso</label>
                                                    <span style={{ color: '#000' }}>{imagen.peso}</span>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Resolución</label>
                                                    <span style={{ color: '#000' }}>{imagen.dimensiones}</span>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Formato</label>
                                                    <span style={{ color: '#000' }}>{imagen.formato}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <BotonDescargar direccion={imagen.url} nombre={imagen.titulo} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {!imagenes || imagenes.length === 0 && (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarImagenes < totalImagenes && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarImagenes(mostrarImagenes + mostrarImagenes)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroSeleccionado === 'videos' || filtroSeleccionado === 'default') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Videos</div>
                            <div className="grid-detalle">
                                {videos && videos.slice(0, mostrarVideos).map((video, index) => (
                                    <div key={index} className="grid-item-detalle">
                                        <div className='fecha-carga-material'>
                                            <div className='nombre-archivo-material'>{video.titulo}</div>
                                            <div className='div-video-text-material'><label>Video</label>
                                            </div>
                                        </div>
                                        <video src={video.url} />
                                        <div className='datos'>
                                            <div className='tecni-info'>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Peso</label>
                                                    <span style={{ color: '#000' }}>{video.peso} </span>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Resolución</label>
                                                    <span style={{ color: '#000' }}>{video.dimensiones} </span>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    <label style={{ color: '#666666' }}>Formato</label>
                                                    <span style={{ color: '#000' }}>{video.formato} </span>
                                                </div>
                                            </div>
                                            <div>
                                                <BotonDescargar direccion={video.url} nombre={video.titulo} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {!videos || videos.length === 0 && (
                                    <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                                )}
                            </div>
                            {mostrarVideos < totalVideos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarVideos(mostrarVideos + mostrarVideos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {(filtroSeleccionado === 'archivos' || filtroSeleccionado === 'default') && (
                        <div>
                            <div className='text subtitulo' style={{ marginBottom: 0 }}>Archivos</div>
                            {archivos && archivos.slice(0, mostrarArchivos).map((archivo, index) => (
                                <div className='manual-marca' style={{ border: 'none', marginBottom: '20px' }} key={index}>
                                    <div style={{ width: '7%' }}>
                                        <Icon
                                            className='download-icon'
                                            style={{
                                                color: '#706f6f',
                                                marginTop: '16px',
                                                float: 'left',
                                                marginLeft: '10%',
                                                fontSize: '26px'
                                            }}
                                            name="file"
                                        />
                                    </div>
                                    <div style={{ width: '84%' }}>
                                        <div className='conten-text-manual-carga'>
                                            {screen.width <= 600 ? (<label className='text-manual-carga'>{archivo.titulo.substring(0, 20) + '...' + archivo.formato}</label>
                                            ) : (<label className='text-manual-carga'>{archivo.titulo + '.' + archivo.formato}</label>)}
                                            <br />
                                            <span style={{ color: '#666666', marginRight: '10px' }}>Fecha de carga: </span> <span>{archivo.fecha_carga}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '7%' }}>
                                        <IconDescargar direccion={archivo.url} nombre={archivo.titulo} />
                                    </div>
                                </div>
                            ))}
                            {mostrarArchivos < totalArchivos && (
                                <div style={{ marginBottom: '30px' }}>
                                    <button className='button-ver-mas-archivos'
                                        onClick={() => setMostrarArchivos(mostrarArchivos + mostrarArchivos)}
                                    >
                                        <span className="text">Ver más</span>
                                    </button>
                                </div>
                            )}
                            {!archivos || archivos.length === 0 && (
                                <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay archivos disponibles.</div>
                            )}
                        </div>

                    )}
                </>
            )}

        </div>
    );
}
export default ResultadoCerrado;