import React, { useEffect, useState } from 'react';
import { get } from '../../Api';
import "../../assets/styles/Home.css";
import { Input, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


function Home() {
  const [marcas, setMarcas] = useState([]);
  const [marcaBuscar, setMarcaBuscar] = useState('');
  const [resultadoBusqueda, setResultadoBusqueda] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get('/getMarcas');
        setMarcas(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [marcaBuscar]);

  const handleSearch = () => {
    const filteredResults = marcas.filter(marca =>
      marca.Nombre.toLowerCase().includes(marcaBuscar.toLowerCase())
    );
    setResultadoBusqueda(filteredResults);
  };

  return (
    <div className='principal' style={{ marginLeft: '7%' }}>
      <div style={{ fontSize: '12px', marginBottom: '10px' }} className='text-home'>Inicio</div>
      <div style={{ fontSize: '32px', marginBottom: '10px' }} className='text-home'>Búsqueda por Marcas</div>
      <div style={{ fontSize: '14px', marginBottom: '30px' }} className='text-home'></div>

      <div className='div-input-home'>
        <Input
          style={{ width: '100%' }}
          icon={true}
          placeholder="Buscar en esta categoría..."
        >
          <input
            value={marcaBuscar}
            onChange={e => setMarcaBuscar(e.target.value)}
          />
          <Icon
            style={{ color: 'black', backgroundColor: '#f9c632' }}
            name="search"
            inverted
            square="true"
            link
            onClick={handleSearch}
          />
        </Input>
      </div>

      <div style={{ fontSize: '18px', marginTop: '50px', marginBottom: '20px' }} className='text-home'>Nuestras marcas</div>
      <div className="grid-home">
        {isLoading ? ( // Mostrar el loader mientras se cargan las marcas
          <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>Cargando marcas...</div>
        ) : resultadoBusqueda.length > 0 ? (
          resultadoBusqueda.map((marca, index) => (
            <Link
              key={index} to="/motos"
              state={{ idMarca: marca.Id, nombreMarca: marca.Nombre }}
              title={marca.Nombre}
            >
              <div key={index} className="grid-item-home">
                <div className="grid-item-imagen-home">
                  {marca.FotosPrincipales && Object.keys(marca.FotosPrincipales).length > 0 && (
                    <img
                      src={marca.FotosPrincipales[Object.keys(marca.FotosPrincipales)[0]][1]}
                      alt={`Imagen ${index + 1}`}
                    />
                  )}
                </div>
                <div className="grid-item-logo-home">
                  {marca.Logos && Object.keys(marca.Logos).length > 0 && (
                    <img
                      src={marca.Logos[Object.keys(marca.Logos)[0]][1]}
                      alt={`Imagen ${index + 1}`}
                    />
                  )}
                </div>
              </div>
            </Link>
          ))
        ) : (         
          marcas.length > 0 ? (
            marcas.map((marca, index) => (
              <Link
                key={index}
                to={`/motos/${marca.Id}/${marca.Nombre.toLowerCase()}`} // Cambiar la ruta para incluir la ID de la marca
                state={{ idMarca: marca.Id, nombreMarca: marca.Nombre }}
                title={marca.Nombre}
              >
                <div key={index} className="grid-item-home">
                  <div className="grid-item-imagen-home">
                    {marca.FotosPrincipales && Object.keys(marca.FotosPrincipales).length > 0 && (
                      <img
                        src={marca.FotosPrincipales[Object.keys(marca.FotosPrincipales)[0]][1]}
                        alt={`Imagen ${index + 1}`}
                      />
                    )}
                  </div>
                  <div className="grid-item-logo-home">
                    {marca.Logos &&
                      Object.keys(marca.Logos).length > 0 && (
                        <img
                          src={marca.Logos[Object.keys(marca.Logos)[0]][1]}
                          alt={`Imagen ${index + 1}`}
                        />
                      )}
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className='text-home' style={{ marginLeft: 0, marginBottom: '40px' }}>No hay marcas disponibles.</div>
          )
        )}
      </div>
    </div>
  );
}

export default Home;